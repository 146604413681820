import _ from 'lodash'
import React from 'react'

const Description = ({
  data,
  t,
}) => (
  <div className='row caseStudy__description'>
    <div className='col-12 col-md-4'>
      <h2>Product background</h2>
    </div>
    <div className='col-12 col-md-8 caseStudy__description__content'>
      <div>
        <div className='rich-text' dangerouslySetInnerHTML={{ __html: data.copy }} />
      </div>
    </div>
  </div>
)

export default Description
